<script setup>
import {useBaseStore} from "~/stores/base";
import PopoverLoginContent from "~/components/PopoverLoginContent.vue";

/** props **/
const props = defineProps({
  open: {
    default: false,
    required: true
  }
})
/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="popover-login-mobile" v-if="props.open && isMobile">
    <div class="arrow"></div>
    <div class="shadow">
      <popover-login-content/>
    </div>
  </div>
  <div class="popover-login-desktop" v-if="props.open && !isMobile">
    <div class="arrow"></div>
    <div class="shadow">
      <popover-login-content/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";
.shadow {
  /* Shadow/lg */
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
}

.popover-login-mobile {
  cursor: default;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 32px;
  padding-top: 25px;
  padding-right: 8px;

  .arrow {
    width: 12px;
    height: 12px;
    rotate: 45deg;
    background-color: white;
    border-radius: 3px;

    position: absolute;
    top: 20px;
    right: 28px;
  }
}

.popover-login-desktop {
  cursor: default;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 32px;
  padding-top: 25px;
  padding-right: 8px;

  .arrow {
    width: 12px;
    height: 12px;
    rotate: 45deg;
    background-color: white;
    border-radius: 3px;

    position: absolute;
    top: 20px;
    right: 28px;
  }
}
</style>
